// GLOBAL VARIABLES ------------------------------------------------
var wRatio = $(window).width() / $(window).height();
var wWidth = $(window).width();
var menuTimeout;

// ON DOCUMENT READY ------------------------------------------------
$(document).ready(function() {

  // customCookieBar();

	// delays a fraction of second the cookie popup 
  // setTimeout(function(){
	//   $('.cookies').css('opacity', 1);
	// }, 500);

	// checks if local storage has cookie 
  if (!!localStorage.getItem('cookieconsent')) {
    document.body.classList.add('cookieconsent')
  } 
  else {
    $('.cookies-button').click(function() {
      localStorage.setItem('cookieconsent', 'ok')
      $('.cookies').hide();
    });
  }

	$('.slideshow').flickity({
	  cellAlign: 'left',
	  pageDots: false,
	  contain: true,
      imagesLoaded: true,
	  selectedAttraction: 0.06,
	  friction: 0.6,
    accessibility: false
	});

  //Watch for transition ends
  $( ".menu" ).each(function() {
    this.addEventListener(transitionEvent, function() {
      if(!this.classList.contains('open')) {
        this.classList.add('hidden')
      }
    })
  });

  $('[data-menu-id]').bind('click', function(e) {
	var this_id = $(this).attr('data-menu-id');
	$('body').addClass('no-scroll');
  	$('.menus').addClass('open');
    $('.menus').scrollTop(0);
  	$('.menus .' + this_id).addClass('open');
    $('.menus .' + this_id).removeClass('hidden');
    
  	e.stopPropagation();
  });

  $(window).click(function(e) {
	  if (!$(e.target).closest('.menus').length) {
	  	closeMenu();
	  }
  });

   $('.close').bind('click', closeMenu);

   $('.close').mouseover(function() {
    		$('.menus').addClass('nudge');
  	});
     $('.close').mouseout(function() {
    		$('.menus').removeClass('nudge');
  	});

});

function closeMenu() {
  var openMenu = $('.menu.open')
  $('body').removeClass('no-scroll');
  $('.menus').removeClass('open nudge');
  $('.menu').removeClass('open');
}

function whichTransitionEvent(){
  var t,
      el = document.createElement("fakeelement");

  var transitions = {
    "transition"      : "transitionend",
    "OTransition"     : "oTransitionEnd",
    "MozTransition"   : "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  }

  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }
}

// function customCookieBar() {
// 	if ( $('#cookies-bar').length > 0 ) {
// 		if ( !Cookies.get('accepted_cookies') ) {
// 			$('#cookies-bar').fadeIn();
// 		}

// 		$('.cookies-button').on('click', function () {
// 			Cookies.set('accepted_cookies', 'true', {expires: $('#cookies-bar').data('notice-keep-hidden-for-n-days')});
// 			$('#cookies-bar').css('display', 'none')
// 		});
// 	}
// }

var transitionEvent = whichTransitionEvent();
